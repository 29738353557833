
  import Vue from 'vue';

  export default Vue.extend({
    name: 'es-massage-modal',

    props: {
      product: {
        type: Object,
        required: true,
      },
    },
  });
